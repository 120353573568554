import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export type IconWrapperProps = PropsWithChildren<{
  width?: number;
  height?: number;
  viewBoxWidth?: number;
  viewBoxHeight?: number;
  className?: string;
}>;

const DEFAULT_SIZE = 24;

const IconWrapper = ({
  children,
  width = DEFAULT_SIZE,
  height = width,
  viewBoxWidth = width,
  viewBoxHeight = height,
  className,
}: IconWrapperProps) => {
  const classes = classNames('h-[1em] inline-block', className);

  return (
    <div className={classes}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox={`0 0 ${viewBoxWidth} ${viewBoxHeight}`}
        className="w-full h-full fill-current"
      >
        {children}
      </svg>
    </div>
  );
};

export default IconWrapper;
