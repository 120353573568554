import IconWrapper, { IconWrapperProps } from '../IconWrapper';

type LogoProps = Pick<IconWrapperProps, 'className'> & {
  height?: number;
};

const Logo = ({ height = 63, ...props }: LogoProps) => (
  <IconWrapper width={height * (515 / 63)} height={height} {...props}>
    <g>
      <path
        d="M9.78999 9.21V27.03H30.5V35.04H9.78999V61.68H-0.0100098V0.93H31.67V9.21H9.78999Z"
        fill="inherit"
      />
      <path
        d="M79 40.44H59.58L66.42 21.63C67.5745 18.4893 68.5068 15.2714 69.21 12H69.39C70.0554 15.1829 70.958 18.3117 72.09 21.36L79 40.44ZM97.18 61.68L75.15 0.93H64.26L42.48 61.68H52.38L57.06 48.27H81.63L86.31 61.68H97.18Z"
        fill="inherit"
      />
      <path
        d="M155.76 11.82C150.602 9.77405 145.109 8.70587 139.56 8.66999C127.32 8.66999 117.51 16.59 117.51 31.62C117.51 45.62 125.51 53.85 138.57 53.85C141.747 53.8686 144.906 53.3825 147.93 52.41V30.36H157.74V58.89C151.234 61.3222 144.346 62.5719 137.4 62.58C118.59 62.58 106.98 51.33 106.98 32.58C106.98 13.83 119.58 0.0899951 140.37 0.0899951C146.111 0.0796357 151.819 0.960105 157.29 2.69999L155.76 11.79V11.82Z"
        fill="inherit"
      />
      <path
        d="M180.5 61.68V0.93H212.55V9.12H190.32V26.4H211.47V34.41H190.32V53.31H212.55V61.68H180.5Z"
        fill="inherit"
      />
      <path
        d="M262.6 18.12C262.6 24.12 257.83 28.2 249.91 28.2C247.75 28.2 246.04 28.11 245.14 28.02V8.76C246.58 8.67 248.38 8.58 250.27 8.58C258.46 8.58 262.6 11.46 262.6 18.12ZM276.82 61.68L260.82 40.53C259.29 38.53 257.4 36.21 255.69 34.05C265.59 32.97 272.88 26.76 272.88 17.05C272.88 4.99 264.24 0.580002 251.64 0.580002C244.8 0.580002 239.76 0.669999 235.35 0.939999V61.69H245.16V35.4H245.61L264.51 61.68H276.82Z"
        fill="inherit"
      />
      <path
        d="M333.94 61.68V34.41H305.23V61.68H295.42V0.93H305.23V26.04H333.94V0.93H343.66V61.68H333.94Z"
        fill="inherit"
      />
      <path
        d="M389.44 62.58C370.36 62.58 366.31 52.14 366.31 40.98V0.93H376.12V40.26C376.12 48.36 378.82 54.48 390.07 54.48C399.97 54.48 404.47 50.34 404.47 39V0.93H414.1V38.28C414.1 54.48 405.37 62.58 389.44 62.58Z"
        fill="inherit"
      />
      <path
        d="M437.48 61.68V0.93H447.29V53.13H468.17V61.68H437.48Z"
        fill="inherit"
      />
      <path
        d="M498.3 9.21V61.68H488.49V9.21H471.84V0.93H515.04V9.21H498.3Z"
        fill="inherit"
      />
    </g>
  </IconWrapper>
);

export default Logo;
