import { useEffect } from 'react';

export const useKeyDown = (keyDownCallback: any) =>
  useEffect(() => {
    document.body.addEventListener('keydown', keyDownCallback as any);

    return () => {
      document.body.removeEventListener('keydown', keyDownCallback as any);
    };
  }, [keyDownCallback]);
