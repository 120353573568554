import dynamic from 'next/dynamic';

const Icons = {
  Logo: dynamic(() => import('./Logo')),
  Chevron: dynamic(() => import('./ui/Chevron')),
  Arrow: dynamic(() => import('./ui/Arrow')),
  Close: dynamic(() => import('./ui/Close')),
  Home: dynamic(() => import('./ui/Home')),
  Play: dynamic(() => import('./ui/Play')),
};

export default Icons;
