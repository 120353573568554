import { useEffect } from 'react';

export const usePreventBodyScroll = (isBodyScrollPrevented: boolean) => {
  useEffect(() => {
    const setOverflowPropertyToBody = (value: string) => {
      document.body.style.overflow = value;
    };

    if (isBodyScrollPrevented) {
      setOverflowPropertyToBody('hidden');
    } else {
      setOverflowPropertyToBody('unset');
    }

    return () => setOverflowPropertyToBody('unset');
  }, [isBodyScrollPrevented]);
};
