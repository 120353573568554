import Content from 'components/Content';
import CopyrightFooter from 'compositions/CopyrightFooter';
import Logo from 'components/icons/Logo';
import RichText from 'components/ui/RichText';
import Text from 'components/ui/Text';
import { FC, PropsWithChildren } from 'react';
import type { PortableTextBlock } from '@portabletext/types/dist';

type GeneralInfoProps = PropsWithChildren<{
  noGrid?: boolean;
  title: string;
  subtitle?: string;
  text: PortableTextBlock[];
}>;

const GeneralInfo: FC<GeneralInfoProps> = ({
  title,
  subtitle,
  text,
  noGrid,
  children,
}) => (
  <>
    <div className="min-h-screen-ios grid grid-rows-[1fr_auto]">
      <div className="flex flex-col items-center justify-center h-full pt-10">
        <Content noMargin className="flex flex-col items-center justify-center">
          <Logo className="!h-6 text-blue" />

          <Text
            bold
            center
            size="huge"
            color="grey-800"
            elementType="h1"
            className="mt-8 mb-8"
          >
            {title}
          </Text>

          {subtitle && (
            <Text
              bold
              center
              size="xlarge"
              color="grey-800"
              elementType="h2"
              className="mb-8"
            >
              {subtitle}
            </Text>
          )}

          <Text elementType="div" center maxWidth="large" className="mb-20">
            <RichText text={text} />
          </Text>
        </Content>
        <Content
          noMargin
          className={
            noGrid ? 'w-full' : 'grid grid-flow-col gap-4 justify-items-center'
          }
        >
          {children}
        </Content>
      </div>

      <CopyrightFooter />
    </div>
  </>
);

export default GeneralInfo;
