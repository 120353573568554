import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';

const useScreenSizeGuard = () => {
  const [width, setWidth] = useState(0);
  const router = useRouter();

  const setDimension = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (window !== undefined) {
      setDimension();
      window.addEventListener('resize', setDimension);

      return () => {
        window.removeEventListener('resize', setDimension);
      };
    }
  }, []);

  useEffect(() => {
    if (window !== undefined && width && width < 500) {
      router.push('/unsupported-screen');
    }
  }, [width, router]);
};

export default useScreenSizeGuard;
