const full = [
  { lang: 'en', flag: 'gb', name: 'English' },
  { lang: 'sv', flag: 'se', name: 'Svenska' },
  { lang: 'no', flag: 'no', name: 'Norsk' },
  { lang: 'da', flag: 'dk', name: 'Dansk' },
  { lang: 'fi', flag: 'fi', name: 'Suomi' },
  { lang: 'fr', flag: 'fr', name: 'Français' },
  { lang: 'nl', flag: 'nl', name: 'Nederlands' },
  { lang: 'es', flag: 'es', name: 'Español' },
  { lang: 'de', flag: 'de', name: 'Deutsch' },
];

module.exports = {
  short: full.map((item) => item.lang),
  full,
};
